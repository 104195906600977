<template>
  <div class="BillingDetailsSummary">
    <md-card class="card-billing-details-summary">
      <md-card-header class="card-header">
        <md-card-header-text>
          <div class="md-title o-default-h3 card-billing-details-title">{{ $t('billingDetailsSummary.title') }}</div>
          <span class="o-default-body">{{ $t('billingDetailsSummary.description') }}</span>
        </md-card-header-text>

        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" md-menu-trigger>
            <md-icon>more_vert</md-icon>
          </md-button>

          <md-menu-content>
            <md-menu-item @click="editBillingDetails(billingDetailsDefault)">
              <span>{{ $t('common.actions.edit') }}</span>
              <md-icon>edit</md-icon>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </md-card-header>

      <Loader v-if="isLoading" />
      <md-card-content class="card-content" v-else-if="isBillingDetailsEditState">
        <BillingDetailsForm />
      </md-card-content>
      <md-card-content class="card-content" v-else>
        <BillingDetailsDefault :billingDetailsDefault="billingDetailsDefault" />
      </md-card-content>
    </md-card>
  </div> <!-- /.BillingDetailsSummary -->
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers.js';
import Loader from '@/components/util/Loader';
import BillingDetailsForm from '@/components/billing-details/BillingDetailsForm.vue';
import BillingDetailsDefault from '@/components/billing-details/BillingDetailsDefault';
import {
  SET_BILLING_DETAILS_EDIT_STATE_FLAG,
  UPDATE_BILLING_DETAILS
} from '@/store/actions.type';
import {
  SET_RESET_BILLING_DETAILS_ERROR
} from '@/store/mutations.type';

export default {
  name: 'BillingDetailsSummary',
  props: {
    billingDetailsDefault: {
      type: Object,
      required: true
    }
  },
  components: {
    Loader,
    BillingDetailsForm,
    BillingDetailsDefault
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['isBillingDetailsEditState'])
  },
  methods: {
    editBillingDetails(details) {
      this.isLoading = true;
      this.$store.dispatch(UPDATE_BILLING_DETAILS, details)
        .then(() => {
          this.$store.dispatch(SET_BILLING_DETAILS_EDIT_STATE_FLAG, true)
            .then(() => {
              this.$store.commit(SET_RESET_BILLING_DETAILS_ERROR);
              Helpers.scrollIntoView('BillingDetailsSummary');
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.BillingDetailsSummary {

  .card-billing-details-summary {
    margin: 0 0 1.6rem;
  }

  .card-billing-details-title {
    margin-bottom: 1.5rem;
  }

  .card-header {
    padding: 1.4rem 2.4rem;
  }

  .card-content {
    padding: 0 2.4rem 1.4rem;
  }
}
</style>
