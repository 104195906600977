<template>
  <div class="BillingDetailsExistingEntry">
    <div class="billing-details-existing-entry__content">
      <div class="o-default-h5">{{ billingDetailsExistingEntry.registrantName }} - {{ Helpers.showEntityType(billingDetailsExistingEntry.personType) }}</div>
      <div v-if="billingDetailsExistingEntry.cnp" class="o-default-body">
        {{ $t('common.addressLabels.identificationNumber') }}: {{ billingDetailsExistingEntry.cnp }}
      </div>
      <div v-if="billingDetailsExistingEntry.registrationNumber" class="o-default-body">
        {{ $t('common.addressLabels.referenceNumber') }}: {{ billingDetailsExistingEntry.registrationNumber }}
      </div>
      <div v-if="billingDetailsExistingEntry.address1">
        <span class="o-default-body">{{ $t('common.addressLabels.address') }}: {{ billingDetailsExistingEntry.address1 }}</span>
        <span v-if="billingDetailsExistingEntry.address2" class="o-default-body">, {{ billingDetailsExistingEntry.address2 }}</span>
        <span v-if="billingDetailsExistingEntry.city" class="o-default-body">, {{ billingDetailsExistingEntry.city }}</span>
        <span v-if="billingDetailsExistingEntry.state" class="o-default-body">, {{ billingDetailsExistingEntry.state }}</span>
        <span v-if="billingDetailsExistingEntry.country" class="o-default-body">, {{ billingDetailsExistingEntry.country }}</span>
      </div>
      <div v-if="billingDetailsExistingEntry.email" class="o-default-body">
        {{ $t('common.addressLabels.email') }}: {{ billingDetailsExistingEntry.email }}
      </div>
      <div v-if="billingDetailsExistingEntry.phone" class="o-default-body">
        {{ $t('common.addressLabels.phone') }}: {{ billingDetailsExistingEntry.phone }}
      </div>
    </div> <!-- /.billing-details-existing-entry__content -->
    <div class="billing-details-existing-entry__actions">
      <md-button class="md-dense md-raised md-primary">
        {{ $t('common.actions.select') }}
      </md-button>
    </div> <!-- /.billing-details-existing-entry__actions -->
  </div> <!-- /.BillingDetailsExistingEntry -->
</template>

<script>
export default {
  name: 'BillingDetailsExistingEntry',
  props: {
    billingDetailsExistingEntry: {
      type: Object,
      required: false
    },
  }
}
</script>

<style lang="scss" scoped>
.BillingDetailsExistingEntry {
  display: flex;
  flex-wrap: wrap;
  padding: 1.6rem 0;
  width: 100%;

  .o-default-h5 {
    margin-bottom: .4rem;
  }

  .billing-details-existing-entry__content {
    flex: 0 0 100%;
    @include tablet { flex: 1; }
  }

  .billing-details-existing-entry__actions {
    @include flex-center-xy;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.6rem -.8rem 0;
    @include tablet { justify-content: flex-end; margin: 0; }
  }
}
</style>
