<template>
  <div class="BillingDetailsForm">
    <form id="billing-details-form" novalidate class="md-layout" @submit.prevent="validateBillingDetails">
      <md-field name="billing-details-name" :class="getValidationClass('registrantName')">
        <label for="billing-details-name">{{ $t('billingDetailsForm.name') }}</label>
        <md-input id="billing-details-name"
                  autocomplete="billing-details-name"
                  v-model.trim="billingDetails.registrantName"
                  :disabled="isFormSending" />
        <span class="md-error" v-if="!$v.billingDetails.registrantName.required">{{ $t('billingDetailsForm.error.nameRequired') }}</span>
        <span class="md-error" v-else-if="!$v.billingDetails.registrantName.minLength">{{ $t('billingDetailsForm.error.nameMinLength') }}</span>
      </md-field>

      <md-field name="billing-details-entity-type" :class="getValidationClass('entityType')">
        <label for="billing-details-entity-type">{{ $t('billingDetailsForm.entityType') }}</label>
        <md-select id="billing-details-entity-type"
                   v-model="billingDetails.entityType"
                   md-dense
                   @md-selected="updateEntityType()"
                   :disabled="isFormSending">
          <EntityTypeOptions />
        </md-select>
        <span class="md-error">{{ $t('billingDetailsForm.error.entityTypeRequired') }}</span>
      </md-field>

      <md-field name="billing-details-identification-number" :class="getValidationClass('identificationNumber')">
        <label for="billing-details-identification-number">{{ $t('billingDetailsForm.identificationNumber') }}</label>
        <md-input id="billing-details-identification-number"
                  autocomplete="billing-details-identification-number"
                  v-model.trim="billingDetails.identificationNumber"
                  :disabled="isFormSending" />
        <span class="md-error" v-if="!$v.billingDetails.identificationNumber.required">{{ $t('billingDetailsForm.error.identificationNumberRequired') }}</span>
        <span class="md-error" v-else-if="!$v.billingDetails.identificationNumber.minLength">{{ $t('billingDetailsForm.error.identificationNumberMinLength') }}</span>
        <span class="md-error" v-else-if="!$v.billingDetails.identificationNumber.maxLength">{{ $t('billingDetailsForm.error.identificationNumberMaxLength') }}</span>
      </md-field>

      <md-field for="billing-details-reference-number" v-if="isCommercialEntity"
                :class="getValidationClass('referenceNumber')">
        <label for="billing-details-reference-number">{{ $t('billingDetailsForm.referenceNumber') }}</label>
        <md-input id="billing-details-reference-number"
                  autocomplete="billing-details-reference-number"
                  v-model.trim="billingDetails.referenceNumber"
                  :disabled="isFormSending" />
        <span class="md-error" v-if="!$v.billingDetails.referenceNumber.required">{{ $t('billingDetailsForm.error.referenceNumberRequired') }}</span>
        <span class="md-error" v-else-if="!$v.billingDetails.referenceNumber.minLength">{{ $t('billingDetailsForm.error.referenceNumberMinLength') }}</span>
      </md-field>

      <md-field name="billing-details-address-1" :class="getValidationClass('address1')">
        <label for="billing-details-address-1">{{ $t('billingDetailsForm.address') }}</label>
        <md-input id="billing-details-address-1"
                  autocomplete="billing-details-address-1"
                  v-model.trim="billingDetails.address1"
                  :disabled="isFormSending" />
        <span class="md-error" v-if="!$v.billingDetails.address1.required">{{ $t('billingDetailsForm.error.addressRequired') }}</span>
        <span class="md-error" v-else-if="!$v.billingDetails.address1.minLength">{{ $t('billingDetailsForm.error.addressMinLength') }}</span>
      </md-field>

      <md-field name="billing-details-address-2">
        <label for="billing-details-address-2">{{ $t('billingDetailsForm.address2') }}</label>
        <md-input id="billing-details-address-2"
                  autocomplete="billing-details-address-2"
                  v-model.trim="billingDetails.address2"
                  :disabled="isFormSending" />
      </md-field>

      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field name="billing-details-address-2" :class="getValidationClass('city')">
            <label for="billing-details-city">{{ $t('billingDetailsForm.city') }}</label>
            <md-input id="billing-details-city"
                      autocomplete="billing-details-city"
                      v-model.trim="billingDetails.city"
                      :disabled="isFormSending" />
            <span class="md-error" v-if="!$v.billingDetails.city.required">{{ $t('billingDetailsForm.error.cityRequired') }}</span>
          </md-field>
        </div> <!-- /.md-layout-item -->

        <div class="md-layout-item md-small-size-100">
          <md-field name="billing-details-reference-number" :class="getValidationClass('state')">
            <label for="billing-details-state">{{ $t('billingDetailsForm.state') }}</label>
            <md-input id="billing-details-state"
                      autocomplete="billing-details-state"
                      v-model.trim="billingDetails.state"
                      :disabled="isFormSending" />
            <span class="md-error" v-if="!$v.billingDetails.state.required">{{ $t('billingDetailsForm.error.stateRequired') }}</span>
          </md-field>
        </div> <!-- /.md-layout-item -->

        <div class="md-layout-item md-small-size-100">
          <md-field name="billing-details-reference-number" :class="getValidationClass('postalCode')">
            <label for="billing-details-postal-code">{{ $t('billingDetailsForm.postalCode') }}</label>
            <md-input id="billing-details-postal-code"
                      autocomplete="billing-details-postal-code"
                      v-model.trim="billingDetails.postalCode"
                      :disabled="isFormSending" />
            <span class="md-error" v-if="!$v.billingDetails.postalCode.required">{{ $t('billingDetailsForm.error.postalCodeRequired') }}</span>
            <span class="md-error" v-if="!$v.billingDetails.postalCode.minLength">{{ $t('billingDetailsForm.error.postalCodeMinLength') }}</span>
          </md-field>
        </div> <!-- /.md-layout-item -->

        <div class="md-layout-item md-small-size-100">
          <md-autocomplete name="user-billingDetails-country"
                           @md-selected="onCountrySelected"
                           :class="getValidationClass('country')"
                           v-model="countryValue"
                           :md-options="countries">
            <label for="user-billingDetails-country">{{ $t('userDetailsForm.country') }}</label>
            <template id="user-billingDetails-country" slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
            </template>
            <template id="user-billingDetails-country" slot="md-autocomplete-empty" slot-scope="{ term }">
              {{ $t('common.error.noCountriesMatchingContent', { term }) }}
            </template>
            <span class="md-error" v-if="!$v.billingDetails.country.required">{{ $t('billingDetailsForm.error.countryRequired') }}</span>
          </md-autocomplete>
        </div> <!-- /.md-layout-item -->
      </div> <!-- /.md-layout -->

      <md-field name="billing-details-email" :class="getValidationClass('email')">
        <label for="billing-details-email">{{ $t('billingDetailsForm.email') }}</label>
        <md-input id="billing-details-email"
                  type="email"
                  name="billing-details-email"
                  autocomplete="billing-details-email"
                  v-model.trim="billingDetails.email"
                  :disabled="isFormSending" />
        <span class="md-error" v-if="!$v.billingDetails.email.required">{{ $t('billingDetailsForm.error.emailRequired') }}</span>
        <span class="md-error" v-else-if="!$v.billingDetails.email.email">{{ $t('billingDetailsForm.error.emailValid') }}</span>
      </md-field>

      <div class="vue-tel-input-wrapper">
        <vue-tel-input class="phone-input"
                       :class="{ 'u-red u-border-red': showPhoneErrors() }"
                       v-model="billingDetails.phone"
                       @input="onPhoneInput"
                       v-bind="vueTelInputProps">
        </vue-tel-input>
        <div class="u-red phone-input-error"
             v-if="showPhoneErrors()">
          <div>{{ $t('common.phone.error.invalid') }}</div>
          <div>{{ $t('common.error.furtherIssuesContact') }} {{ $t('contactEmailInreg') }}</div>
        </div>
      </div> <!-- /.vue-tel-input-wrapper -->

      <TermsAndConditions />

      <md-button type="submit"
                 class="md-raised md-primary submit-btn"
                 :disabled="isFormSending">
        {{ $t('common.actions.save') }}
      </md-button>
      <md-button v-if="isBillingDetailsAddState"
                 class="md-raised md-accent cancel-btn"
                 :disabled="isFormSending"
                 @click="cancelAdd()">
        {{ $t('common.actions.cancel') }}
      </md-button>
      <md-button v-if="isBillingDetailsEditState"
                 class="md-raised md-accent cancel-btn"
                 :disabled="isFormSending"
                 @click="cancelEdit()">
        {{ $t('common.actions.cancel') }}
      </md-button>
      <md-button v-if="isBillingDetailsOtherEditState"
                 class="md-raised md-accent cancel-btn"
                 :disabled="isFormSending"
                 @click="cancelEditOther()">
        {{ $t('common.actions.cancel') }}
      </md-button>

      <div v-if="billingDetailsError"
           v-html="billingDetailsError"
           class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 u-default-body u-red phone-input-error">
      </div>
    </form>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState
 } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import Helpers from '@/common/helpers.js';
import EntityTypeOptions from '@/components/util/EntityTypeOptions';
import TermsAndConditions from '@/components/TermsAndConditions';
import {
  POST_BILLING_DETAILS_ADD,
  POST_BILLING_DETAILS_EDIT,
  SET_BILLING_DETAILS_ADD_STATE_FLAG,
  SET_BILLING_DETAILS_EDIT_STATE_FLAG,
  SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG,
  SHOW_CONFIRMATION_DIALOG,
  FETCH_BILLING_DETAILS_ALL
} from '@/store/actions.type';
import {
  SET_RESET_BILLING_DETAILS_ERROR
} from '@/store/mutations.type';

export default {
  name: 'BillingDetailsForm',
  mixins: [validationMixin],
  components: {
    EntityTypeOptions,
    TermsAndConditions
  },
  data() {
    return {
      errors: {
        validPhone: false,
        showInvalidPhone: false,
      },
      isFormSending: false,
      commercialEntities: ['nc', 'c', 'gi', 'pi', 'o'],
      isCommercialEntity: false,
      companyValidation: false,
      triedPhoneValidation: false,
      vueTelInputProps: {
        mode: 'international',
        autoDefaultCountry: false,
        preferredCountries: ['ro'],
        inputOptions: {
          placeholder: this.$t('common.phone.phoneNumber')
        }
      },
      countryValue: null,
    };
  },
  computed: {
    ...mapGetters([
      'billingDetails',
      'billingDetailsError',
      'isBillingDetailsAddState',
      'isBillingDetailsEditState',
      'isBillingDetailsOtherEditState',
    ]),
    ...mapState({
      countries: state => state.location.countries
    }),
  },
  watch: {
    'billingDetails.entityType': function (value) {
      if (this.contains(this.commercialEntities, value)) {
        this.companyValidation = true;
      } else {
        this.companyValidation = false;
      }
    },
  },
  created() {
    this.countryValue = this.countrySearchValue(this.billingDetails.country, this.countries);
  },
  mounted() {
    this.updateEntityType();
  },
  methods: {
    contains(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return true;
        }
      }
      return false;
    },
    countrySearchValue(nameKey, array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value === nameKey) {
          return array[i].name;
        }
      }
    },
    updateEntityType() {
      if (this.contains(this.commercialEntities, this.billingDetails.entityType)) {
        this.isCommercialEntity = true;
      } else {
        this.isCommercialEntity = false;
      }
    },
    showConfirmationAndScrollTo(scrollAnchor) {
      Helpers.scrollIntoView(scrollAnchor);
      this.isFormSending = false;
      setTimeout(() => {
        this.$store.dispatch(SHOW_CONFIRMATION_DIALOG)
      }, 500);
    },
    handleUpdateError() {
      Helpers.scrollIntoView('u-red');
      this.isFormSending = false;
    },
    saveBillingDetails() {
      this.isFormSending = true;

      if (this.billingDetails.id && !this.isBillingDetailsAddState) {
        const tempObj = {
          ...this.billingDetails,
          id: this.billingDetails.id
        };
        this.$store.dispatch(POST_BILLING_DETAILS_EDIT, tempObj)
          .then(() => {
            this.$store.dispatch(FETCH_BILLING_DETAILS_ALL)
              .then(() => {
                if (this.isBillingDetailsOtherEditState) {
                  this.$store.dispatch(SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG, false)
                    .then(() => {
                      this.showConfirmationAndScrollTo('BillingDetailsOther')
                    });
                } else {
                  this.$store.dispatch(SET_BILLING_DETAILS_EDIT_STATE_FLAG, false)
                    .then(() => {
                      this.showConfirmationAndScrollTo('BillingDetailsSummary')
                    });
                }
              })
          })
          .catch(() => {
            this.handleUpdateError();
          });
      } else {
        this.$store.dispatch(POST_BILLING_DETAILS_ADD, this.billingDetails)
          .then(() => {
            this.$store.dispatch(FETCH_BILLING_DETAILS_ALL)
              .then(() => {
                this.$store.dispatch(SET_BILLING_DETAILS_ADD_STATE_FLAG, false)
                  .then(() => {
                    this.showConfirmationAndScrollTo('BillingDetailsSummary');
                  });
              });
          })
          .catch(() => {
            this.handleUpdateError();
          });
      }
    },
    validateBillingDetails() {
      this.$v.billingDetails.$touch();
      this.$store.commit(SET_RESET_BILLING_DETAILS_ERROR)
      const validPhoneNumber = this.validatePhoneNumber();
      Helpers.scrollIntoView('md-invalid');

      if (!this.$v.billingDetails.$invalid && validPhoneNumber) {
        this.saveBillingDetails();
      }
    },
    showPhoneErrors() {
      if (this.triedPhoneValidation) {
        if (!this.errors.validPhone) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    onCountrySelected(selectedCountry) {
      this.billingDetails.country = selectedCountry.value;
      this.countryValue = selectedCountry.name;
    },
    onPhoneInput(number, phoneObj) {
      this.errors.validPhone = phoneObj.valid;
    },
    validatePhoneNumber() {
      this.triedPhoneValidation = true;
      if (this.errors.validPhone) {
        this.errors.showInvalidPhone = false;
        return true;
      } else {
        this.errors.showInvalidPhone = true;
        return false;
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.billingDetails[fieldName]

      if (field) {
        return { 'md-invalid': field.$invalid && field.$dirty }
      }
    },
    cancelAdd() {
      this.$store.dispatch(SET_BILLING_DETAILS_ADD_STATE_FLAG, false);
      Helpers.scrollIntoView('BillingDetailsEmpty');
      Helpers.scrollIntoView('BillingDetailsAdd');
    },
    cancelEdit() {
      this.$store.dispatch(SET_BILLING_DETAILS_EDIT_STATE_FLAG, false);
      Helpers.scrollIntoView('BillingDetailsSummary');
    },
    cancelEditOther() {
      this.$store.dispatch(SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG, false);
      Helpers.scrollIntoView('BillingDetailsOther');
    }
  },
  validations: {
    billingDetails: {
      registrantName: {
        required,
        minLength: minLength(3)
      },
      entityType: {
        required
      },
      identificationNumber: {
        required,
        maxLength: maxLength(13)
      },
      referenceNumber: {
        required: requiredIf(function() { return this.companyValidation }),
        minLength: minLength(4)
      },
      address1: {
        required,
        minLength: minLength(3)
      },
      city: {
        required
      },
      state: {
        required
      },
      postalCode: {
        required,
        minLength: minLength(6)
      },
      country: {
        required
      },
      email: {
        required,
        email
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.BillingDetailsForm {
  position: relative;
  z-index: 1;

  .vue-tel-input-wrapper {
    width: 100%;
  }

  .phone-input {
    box-shadow: 0 .2rem .1rem -.1rem rgba(0, 0, 0, .2), 0 .1rem .1rem 0 rgba(0, 0, 0, .14), 0 .1rem .3rem 0 rgba(0, 0, 0, .12);
    display: inline-flex;
    height: 4rem;
    margin: .8rem 0 0;
    min-width: 26rem;
    position: relative;
    width: 100%;
    z-index: 9;
    @include desktop { width: calc(50% - 1.2rem); }
  }

  .phone-input-error {
    font-family: inherit;
    font-size: 1.2rem;
    margin-top: .8rem;
  }

  .submit-btn, .cancel-btn {
    height: 4rem;
    margin: 1.6rem auto;
    min-width: 26rem;
  }
}
</style>
