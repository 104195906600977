<template>
  <div class="BillingDetailsExistingEntries">
    <Loader v-if="isLoading" />
    <md-list v-else-if="!isBillingDetailsAddState && userDetailsAddresses && userDetailsAddresses.length">
      <md-list-item v-for="item in userDetailsAddresses"
                    :key="item.id"
                    @click="showEditForm(item)"
                    class="with-divider">
        <BillingDetailsExistingEntry :billingDetailsExistingEntry="item" />
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/util/Loader';
import Helpers from '@/common/helpers';
import BillingDetailsExistingEntry from '@/components/billing-details/BillingDetailsExistingEntry.vue';
import {
  FETCH_USER_DETAILS_ALL,
  UPDATE_BILLING_DETAILS,
  SET_BILLING_DETAILS_ADD_STATE_FLAG,
  SHOW_BILLING_DETAILS_ADD_DIALOG
} from '@/store/actions.type';

export default {
  name: 'BillingDetailsExistingEntries',
  components: {
    Loader,
    BillingDetailsExistingEntry
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters([
      'isBillingDetailsAddState',
      'userDetailsAddresses'
    ])
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch(FETCH_USER_DETAILS_ALL)
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    showEditForm(item) {
      this.isLoading = true;
      this.$store.dispatch(UPDATE_BILLING_DETAILS, item)
        .then(() => {
          this.$store.dispatch(SET_BILLING_DETAILS_ADD_STATE_FLAG, true)
            .then(() => {
              this.$store.dispatch(SHOW_BILLING_DETAILS_ADD_DIALOG, false)
                .then(() => {
                  Helpers.scrollIntoView('BillingDetailsAdd');
                });
            });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.BillingDetailsExistingEntries {
  margin: 0 auto;
  padding: 2.4rem 0;
  max-width: 64rem;

  .md-list {
    padding-top: 0;
  }
}
</style>
