<template>
  <div class="BillingDetailsEmpty">
    <md-card class="card-billing-details-empty">
      <div v-if="!isBillingDetailsAddState">
        <md-empty-state md-icon="receipt"
                        :md-label="emptyState.label"
                        :md-description="emptyState.description">
          <md-button @click="addNewDetails()"
                    class="md-primary md-raised add-new-details-btn">
            {{ $t('common.actions.addNewBillingDetails') }}
          </md-button>
          <div @click="addNewDetails()" class="clickable-overlay"></div>
        </md-empty-state>
        <div v-if="userDetailsAddresses && userDetailsAddresses.length">
          <div class="o-default-h3 u-text-center">{{ $t('billingDetailsEmpty.useExistingEntry') }}</div>
          <BillingDetailsExistingEntries />
        </div>
      </div>
      <div v-else>
        <md-card-header>
          <md-card-header-text>
            <div class="md-title o-default-h3 card-billing-details-title ">{{ $t('billingDetailsEmpty.titleAdd') }}</div>
            <span class="o-default-body">{{ $t('billingDetailsEmpty.descriptionAdd') }}</span>
          </md-card-header-text>
        </md-card-header>
        <md-card-content>
          <BillingDetailsForm />
        </md-card-content>
      </div>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers';
import BillingDetailsExistingEntries from '@/components/billing-details/BillingDetailsExistingEntries.vue';
import BillingDetailsForm from '@/components/billing-details/BillingDetailsForm.vue';
import {
  RESET_BILLING_DETAILS,
  SET_BILLING_DETAILS_ADD_STATE_FLAG
} from '@/store/actions.type';

export default {
  name: 'BillingDetailsEmpty',
  components: {
    BillingDetailsExistingEntries,
    BillingDetailsForm
  },
  data() {
    return {
      emptyState: {
        label: this.$t('billingDetailsEmpty.label'),
        description: this.$t('billingDetailsEmpty.description')
      }
    };
  },
  computed: {
    ...mapGetters([
      'isBillingDetailsAddState',
      'userDetailsAddresses'
    ])
  },
  methods: {
    addNewDetails() {
      this.$store.dispatch(RESET_BILLING_DETAILS)
        .then(() => {
          this.$store.dispatch(SET_BILLING_DETAILS_ADD_STATE_FLAG, true)
            .then(() => {
              Helpers.scrollIntoView('BillingDetailsEmpty');
            });
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.BillingDetailsEmpty {

  .card-billing-details-empty {
    margin: 0 0 1.6rem;
  }

  .add-new-details-btn {
    height: 4rem;
    min-width: 20rem;
    padding: 0 .8rem;
  }

  .clickable-overlay {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .card-billing-details-title {
    margin-bottom: 1.5rem;
  }
}
</style>
