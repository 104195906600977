<template>
  <div class="EntityTypeOptions">
    <md-option value="p">{{ $t('common.entityType.p') }}</md-option>
    <md-option value="ap">{{ $t('common.entityType.ap') }}</md-option>
    <md-option value="nc">{{ $t('common.entityType.nc') }}</md-option>
    <md-option value="c">{{ $t('common.entityType.c') }}</md-option>
    <md-option value="gi">{{ $t('common.entityType.gi') }}</md-option>
    <md-option value="pi">{{ $t('common.entityType.pi') }}</md-option>
    <md-option value="o">{{ $t('common.entityType.o') }}</md-option>
  </div>
</template>

<script>
export default {
  name: 'EntityTypeOptions'
};
</script>
